<!-- System: STA
    Purpose: Invitation name and password form appears after the invitation email form
            when a new user is invited by a link.
-->

<template>
  <v-app>
    <v-card class="mx-auto mt-10" width="450" v-if="!email_test">
      <a href="https://stafftimerapp.com/" target="_blank">
        <img :src="imgSource" width="400" height="180" />
      </a>
      <p class="minutes-hours-days">A minute can make a difference</p>
      <!-- Invite Link Name & Email Form -->
      <v-form
        @submit.prevent="inviteMemberJoin"
        v-model="valid"
        id="reset_pwd"
        method="post"
        ref="loginForm"
        class="mt-10 form"
        autocomplete="off"
      >
        <v-row class="d-block text-caption">
          <v-col cols="12" v-if="company_name">
            <span>
              <span
                ><b>{{ company_name }} </b></span
              >
              <b
                >has invited you to join their team,<br />
                please enter your email to get started</b
              ></span
            >
          </v-col>
        </v-row>
        <v-row>
          <!-- Name Input Field -->
          <v-col cols="12">
            <v-text-field
              class="ml-8 mr-8"
              :rules="required"
              required
              autofocus
              autocomplete="off"
              name="name"
              label="Your Name"
              type="name"
              v-model="name"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <!-- Email Input Field -->
            <v-text-field
              class="ml-8 mr-8 mt-n5"
              :rules="required"
              required
              autofocus
              autocomplete="off"
              name="email"
              label="Email Address"
              type="email"
              v-model="user_email"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <!-- Password Input Field -->
            <v-text-field
              class="ml-8 mr-8 mt-n5"
              required
              autofocus
              autocomplete="off"
              id="password"
              name="password"
              label="Password"
              :rules="rules"
              v-model="password"
              :append-icon="visible ? 'mdi-eye' : 'mdi-eye-off'"
              :type="visible ? 'text' : 'password'"
              @keyup.native.enter="login()"
              @click:append="visible = !visible"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Complete Setup button -->
        <v-row>
          <v-col cols="12">
            <div class="text-center mb-5">
              <v-btn
                color="#2758f6"
                width="80%"
                :x-large="$vuetify.breakpoint.md || $vuetify.breakpoint.lg"
                :medium="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
                class="white--text text-capitalize text-white mt-5"
                :class="
                  $vuetify.breakpoint.sm || $vuetify.breakpoint.xs
                    ? 'subtitle-1'
                    : 'title'
                "
                ref="submit"
                type="submit"
                :disabled="!valid || loginLoader"
              >
                <v-progress-circular indeterminate v-if="loginLoader" size="20">
                </v-progress-circular>
                Complete Setup</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-app>
</template>
<script>
import { baseURL } from "@/assets/js/urls";
import { emailRules, required } from "@/Constants/Const";
import { mapState } from "vuex";
import router from "../../router";
export default {
  name: "EmailExist",
  props: ["user_email"],
  ...mapState("custom", ["selected_company", "companies_list"]),
  data() {
    return {
      valid: true,
      rules: [
        (value) => !!value || "Required.",
        (value) => (value || "").length > 5 || "Max 6 characters",
      ],
      required: required,
      imgSource: require("@/assets/images/st_logo_new.svg"),
      company_name: "",
      eye: true,
      email: null,
      visible: false,
      name: null,
      password: null,
      invite_token: "",
      loginLoader: false,
    };
  },

  mounted() {
    this.invite_token = this.$route.params.invite_token;
    this.getName();
    this.getEmail();
  },
  methods: {
    /**
     * This function is responsible for get the user email.
     */
    getEmail() {
      return this.user_email;
    },

    /**
     * This function is responsible for get the company name.
     */
    getName() {
      this.$store.commit("custom/toggle_loader", true);
      let data = {
        invite_token: this.invite_token,
      };
      this.$store
        .dispatch("auth/getCompanyName", data)
        .then((response) => {
          this.company_name = response.data.ccompany_name;
          this.$store.commit("custom/toggle_loader", false);
        })
        .catch((error) => {
          this.$store.commit("custom/toggle_loader", false);
          this.$root.$emit("snack_bar", {
            show: true,
            message: error.data.error_msg,
            snackbarColor: "red",
          });
        });
    },

    /**
     * This function is responsible for join invitation.
     * Form data will be dispatch to "auth/join_invitation_link
     * After this Company Listing dispatch call will be executed and
     * sets the company data into the store.
     */
    inviteMemberJoin() {
      if (this.getEmail() && this.password) {
        let formData = {
          email: this.getEmail(),
          password: this.password,
          name: this.name,
          invite_token: this.invite_token,
        };
        this.loginLoader = true;
        this.$store
          .dispatch("auth/join_invitation_link", formData)
          .then((response) => {
            this.$store.commit("custom/first_time", true);
            this.$store.dispatch("auth/setCurrentUser", response.data);
            this.$store.dispatch("custom/getAllCompaniesList", formData);
            this.loginLoader = false;
          })
          .catch((error) => {
            if (error.response.data.error_msg) {
              this.$root.$emit("snack_bar", {
                show: true,
                message: error.response.data.error_msg,
                snackbarColor: "red",
              });
              this.loginLoader = false;
            }
            if (error.response.data.errors) {
              if (error.response.data.errors.name)
                this.$root.$emit("snack_bar", {
                  show: true,
                  message: error.response.data.errors.name[0],
                  snackbarColor: "red",
                });
              if (error.response.data.errors.password)
                this.$root.$emit("snack_bar", {
                  show: true,
                  message: error.response.data.errors.password[0],
                  snackbarColor: "red",
                });
              this.loginLoader = false;
            }
          });
      }
    },
  },
};
</script>
<style scoped></style>
